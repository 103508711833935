import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';

const server = base_url.server

export function selectCorporationBySelf(mobile) {
  return request({
    url: `${server}/auth/dataAuthApproval/selectCorporationBySelf`,
    method: 'post',
    data: { approvalUserMobile: mobile }
  })
}
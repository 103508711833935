import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';

const server = base_url.server


//获取国家信息
export function getCountry() {
  return request({
    url: `${server}/auth/common/getCountry`,
    method: 'get'
  })
}

export function getProvinces(country = '') {
  return request({
    url: `${server}/auth/common/getProvinces?countryName=${country}`,
    method: 'get'
  })
}

export function getCities(country, province) {
  return request({
    url: `${server}/auth/common/getCities?countryName=${country}&provinceName=${province}`,
    method: 'get'
  })
}
<template>
  <el-card class="project-group page-container">
    <el-form slot="header" size="small" :model="projectInformation" :inline="true">
      <el-form-item label="项目名称">
        <el-input v-model="projectInformation.name" clearable @clear="getProjectList">
          <el-button slot="append" icon="el-icon-search" @click="handleClick(projectInformation.name)">查询</el-button>
        </el-input>
      </el-form-item>
      <el-form-item style="float: right; font-size: 14px">
        <el-button size="small" type="primary" @click="handleIncreased" v-permission="['add-P']">新增项目</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="projectList" height="100px">
      <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
      <el-table-column label="编号" prop="pnum" width="120" align="center"></el-table-column>
      <el-table-column label="名称" prop="pname"></el-table-column>
      <el-table-column label="企业" prop="cpName"></el-table-column>
      <el-table-column label="行政区域">
        <template slot-scope="scope">
          <span v-if="scope.row.city">
            {{ `${scope.row.country}${scope.row.provinces}${scope.row.city}` }}
          </span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column label="详细地址" prop="projectaddress"></el-table-column>
      <el-table-column label="联系方式" prop="responsiblePersonMobile"></el-table-column>
      <el-table-column label="负责人" prop="responsiblePersonName"></el-table-column>
      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          {{ $moment(scope.row.createtime).format('YYYY-MM-DD HH:mm') }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="130" align="center">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" v-permission="['edit-P']">编辑</el-button>
          <el-button type="text" icon="el-icon-delete" style="color: #f40" @click="handleRemoveProject(scope.row.pnum)" v-permission="['remove-P']">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <PaginationBar :table-config="tableConfig" @change:pageNumber="handleCurrentChange" />
    <el-dialog :close-on-click-modal="false" :visible="dialogVisible" title="项目信息" width="40%" :before-close="handleClose">
      <el-form ref="PROJECTFORM" :rules="rules" :model="projectInfo" label-position="right" label-width="100">
        <el-form-item label="地址信息" prop="address">
          <el-cascader filterable v-model="projectInfo.address" :props="props" @change="handleAddress(projectInfo.address)" :key="key" v-if="dialogVisible"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="projectAddress">
          <el-input v-model="projectInfo.projectAddress" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="projectInfo.projectName" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="所属企业" prop="cpId" v-if="this.enterpriseInfo.length > 1">
          <el-select v-model="projectInfo.cpId">
            <el-option v-for="cp in enterpriseInfo" :value="cp.cpId" :label="cp.cpName" :key="cp.cpId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="submit('PROJECTFORM')">确 定</el-button>
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import PaginationBar from '@/components/Pagination/index.vue';
import { getProjectList, insertProjectInfo, updateProjectInfo, deleteProject } from '@/api/project';
import { getCountry, getProvinces, getCities } from '@/api/geographic.js';
import { selectCorporationBySelf } from '@/api/order.js';
import { getUserInformation } from '@/utils/auth';
import sleep from '@/utils/sleep';
import statusInclude from '@/utils/statusCode';
export default {
  name: 'ProjectList',
  components: {
    PaginationBar,
  },
  data() {
    return {
      dialogVisible: false,
      isEdit: false,
      countrys: [],
      projectList: [],
      address: [],
      enterpriseInfo: [],
      userInformation: {
        mobile: '',
      },
      projectInformation: {
        name: '',
      },
      projectInfo: {
        address: [],
        country: '',
        provinces: '',
        city: '',
        projectAddress: '',
        projectName: '',
        cpId: '',
      },
      rules: {
        address: [{ required: true, message: '请选择项目地址信息', trigger: 'change' }],
        projectAddress: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        cpId: [{ required: true, message: '请选择项目所属企业', trigger: 'change' }],
        projectName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
      },
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          // 加载地区数据
          const { level, path } = node;
          if (level === 0) {
            const { code, data: countrys, reason } = await getCountry();
            if (!statusInclude(code)) return resolve([{ value: '暂无数据', label: '暂无数据' }]);
            const nodes = countrys.map((country) => ({
              value: country,
              label: country,
            }));
            resolve(nodes);
          }
          if (level === 1) {
            const { code, data: provinces, reason } = await getProvinces(node.value);
            if (!statusInclude(code)) return resolve([{ value: '暂无数据', label: '暂无数据' }]);
            const nodes = provinces.map((province) => ({
              value: province,
              label: province,
            }));
            resolve(nodes);
          }
          if (level === 2) {
            const [country, province] = path;
            const { code, data: citys, reason } = await getCities(country, province);
            if (!statusInclude(code)) return resolve([{ value: '暂无数据', label: '暂无数据' }]);
            const nodes = citys.map((city) => ({
              value: city,
              label: city,
              leaf: level >= 2,
            }));
            resolve(nodes);
          }
        },
      },
      tableConfig: {
        page: 1,
        size: 20,
        total: 0,
      },
    };
  },
  mounted() {
    const { userInformation } = getUserInformation();
    if (userInformation) {
      const { userEmail: email, userName: name, userAvater, userMobile: mobile, userId, roleName, idcardName } = userInformation;
      this.userInformation = { name, mobile, email, userId, roleName, idcardName };
    }
    this.selectCorporationBySelf();
    this.getProjectList({ ...this.tableConfig });
  },
  methods: {
    //当前页改变时触发 跳转其他页
    handleCurrentChange(page) {
      this.tableConfig.page = page;
      this.getProjectList({ ...this.tableConfig });
    },
    handleAddress(address) {
      const [country, provinces, city] = address;
      if (!country || !provinces || !city) return this.$message({ type: 'warning', message: '请选择完成的地址信息' });
      this.projectInfo.country = country;
      this.projectInfo.provinces = provinces;
      this.projectInfo.city = city;
    },
    //清楚筛选项
    handleClick(pName) {
      this.getProjectList({ ...this.tableConfig, pname: pName });
    },

    //新增项目信息
    handleIncreased() {
      this.isEdit = false;
      this.dialogVisible = true;
    },
    handleEdit(project) {
      this.resetForm();
      this.projectInfo = {};
      const { country, provinces, city, projectaddress, pname, cpId, pnum } = project;
      this.projectInfo = {
        address: [country, provinces, city],
        country,
        provinces,
        city,
        projectAddress: projectaddress,
        projectName: pname,
        cpId: cpId,
        pid: pnum,
      };
      this.isEdit = true;
      this.dialogVisible = true;
    },

    //提交项目信息
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const tmp = JSON.parse(JSON.stringify(this.projectInfo));
          delete tmp.address;
          const { code, data, reason } = this.isEdit ? await updateProjectInfo(tmp) : await insertProjectInfo(tmp);
          if (!statusInclude(code)) return this.$message.error(reason);
          this.$message({ type: 'success', message: reason });
          this.dialogVisible = false;
          this.resetForm();
        } else {
          return false;
        }
      });
    },
    //重置form数据
    resetForm(formName = 'PROJECTFORM') {
      for (let key in this.projectInfo) {
        if (key === 'address') {
          this.projectInfo[key] = [];
        } else {
          this.projectInfo[key] = '';
        }
      }
      this.$refs[formName]?.resetFields();
      this.selectCorporationBySelf();
      this.getProjectList({ ...this.tableConfig });
    },
    //关闭dialog
    handleClose() {
      this.resetForm();
      this.isEdit = false;
      this.dialogVisible = false;
    },
    //请求企业信息
    async selectCorporationBySelf() {
      const { code, data, reason } = await selectCorporationBySelf(this.userInformation.mobile);
      if (!statusInclude(code)) return this.$message.error(reason);
      this.enterpriseInfo = data;
      if (data.length > 1) return;
      this.projectInfo.cpId = data[0].cpId;
    },
    //请求项目信息
    async getProjectList({ page = 1, size = 12, pname = '', cpId = '' }) {
      const { code, data, reason } = await getProjectList(page, size, pname, cpId);
      if (!statusInclude(code)) return this.$message.error(reason);
      const { records, total } = data;
      this.tableConfig.total = total;
      this.projectList = records;
    },
    //删除项目信息
    handleRemoveProject(pId) {
      this.$confirm('此操作将删除该项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code, reason } = await deleteProject(pId);
          if (!statusInclude(code)) return this.$message(`删除失败：${reason}`);

          this.getProjectList({ ...this.tableConfig });
          this.$message({ type: 'success', message: '删除成功!' });
        })
        .catch(() => {
          this.$message({ type: 'info', message: '已取消删除' });
        });
    },

    // //请求国家信息
    // async getCountry() {
    //   const { code, data, reason } = await getCountry();
    //   if (!statusInclude(code)) return this.$message.error(reason);
    //   this.countrys = data;
    //   return data;
    // },
    // //请求省市信息
    // async getProvinces(country) {
    //   return await getProvinces(country);
    // },
    // // 求情城市信息
    // async getCities(country, province) {
    //   return await getCities(country, province);
    // },
  },
  computed: {
    key() {
      return new Date().getTime();
    },
  },
  watch: {
    projectInfo: {
      deep: true,
      handler(np) {
        console.log(np.address);
      },
    },
  },
};
</script>
